import { MathfieldElement, Mathfield } from 'mathlive';
import 'mathjax/es5/tex-mml-chtml';

declare global {
  interface Window {
    MathJax: {
      typesetPromise: (elements: any[]) => Promise<void>;
    };
  }
}

export const createMathliveButton = (editor: any) => {
 // console.log('Creating Mathlive Button'); // Debugging log
  return {
    name: "math",
    iconURL: "https://img.icons8.com/ios/50/000000/sigma.png",
    exec: () => openMathModal(editor)
  };
};

const openMathModal = (editor: any) => {

  const modal = document.createElement('div');
  modal.style.position = 'fixed';
  modal.style.top = '50%';
  modal.style.left = '50%';
  modal.style.transform = 'translate(-50%, -50%)';
  modal.style.backgroundColor = '#fff';
  // modal.style.padding = '20px';
  modal.style.width = "400px";
  modal.style.boxShadow = '0 0 10px rgba(0,0,0,0.5)';
  modal.style.zIndex = "1000"; // Ensure modal is on top


  const topcontainer = document.createElement('div');
  topcontainer.style.backgroundColor = 'rgb(238, 238, 238)';
  topcontainer.style.height = '50px';
  topcontainer.style.padding = '16px 10px 10px 10px';

  const title = document.createElement('h2');
  title.innerHTML = "Edit Math";
  title.style.fontWeight = "600";
  topcontainer.appendChild(title);
  modal.appendChild(topcontainer);

  const innerDiv = document.createElement('div');
  innerDiv.style.padding = '20px';
  modal.appendChild(innerDiv);

  const mathContainer = document.createElement('div');
  mathContainer.id = 'math-container';
  innerDiv.appendChild(mathContainer);

  addNewMathField(mathContainer, innerDiv, editor, modal);





  const closeButton = document.createElement('button');
  closeButton.textContent = 'Close';
  closeButton.style.marginTop = '10px';
  closeButton.style.marginLeft = '10px';
  closeButton.style.background = "rgb(250, 155, 49)";
  closeButton.style.padding = "10px";
  closeButton.style.height = "37px";
  closeButton.style.color = "white";
  closeButton.style.alignItems = "center";
  closeButton.style.borderRadius = "8px";
  closeButton.onclick = () => {
    closeModal(modal);
  };
  innerDiv.appendChild(closeButton);

  document.body.appendChild(modal);
};

const addNewMathField = (container: HTMLElement, innerDiv: HTMLElement, editor: any, modal: HTMLElement) => {
  /* const mathfield = new MathfieldElement();
   mathfield.style.width = '100%';
   mathfield.style.height = '100px'; // Adjust height as needed
   container.appendChild(mathfield);*/

  const mathInput = document.createElement('math-field');
  mathInput.style.border = '1px solid black';
  mathInput.style.padding = '10px';
  mathInput.style.margin = '10px 0';
  mathInput.style.minHeight = '50px';
  mathInput.style.width = "100%";
  container.appendChild(mathInput);

  const insertButton = document.createElement('button');
  insertButton.textContent = 'Add Equation';
  insertButton.style.marginTop = '10px';
  insertButton.style.marginLeft = '10px';
  insertButton.style.background = "rgb(250, 155, 49)";
  insertButton.style.padding = "10px";
  insertButton.style.height = "37px";
  insertButton.style.color = "white";
  insertButton.style.alignItems = "center";
  insertButton.style.borderRadius = "8px";

  insertButton.onclick = () => {
    const latexArray = Array.from(container.children).map(child => (child as any).getValue());
    Promise.all(latexArray.map(renderMath)).then((mathHtmlArray) => {
      //console.log(mathHtmlArray, " Array ", mathHtmlArray.join(' '));
      console.log(mathHtmlArray.length, " length ");
      insertMathIntoEditor(editor, mathHtmlArray);
      //editor.s.insertHTML(Array.isArray(mathHtmlArray) ? mathHtmlArray.join(' ') :'');
     // document.body.removeChild(modal);
    });
    closeModal(modal);
  };

  /* insertButton.addEventListener('click', () => {
       const latex = mathInput.getValue('latex');
       const span = document.createElement('span');
       span.innerHTML = `\\(${latex}\\)`;
       container.replaceWith(span);
       window.MathJax.typesetPromise([span]).catch((err) => console.log(err.message));
     });*/
  innerDiv.appendChild(insertButton);

};

const closeModal = (modal: HTMLElement) => {
  document.body.removeChild(modal);
};

const renderMath = (latex: string): Promise<string> => {
  return new Promise((resolve) => {

    const span = document.createElement('span');
    span.className = 'math-expression'; // Apply custom CSS class for styling
    span.style.border = '1px dotted black'; // Apply dotted border
    span.style.padding = '5px'; // Add padding for spacing
    span.style.userSelect = "none";
    span.style.pointerEvents = "none";
    span.contentEditable = "false";

    const container = document.createElement('div');
    container.className = 'math-expression';
    container.innerHTML = `\\(${latex}\\)`;
    window.MathJax.typesetPromise([container]).then(() => {
      span.appendChild(container.firstChild);
      resolve(span.outerHTML);
    });
  });
};

const insertMathIntoEditor = (editor, mathHtmlArray) => {
  try {
      // Ensure editor is ready and not destroyed
      
      if (editor && editor.s && editor.s.insertHTML) {
          editor.s.insertHTML(mathHtmlArray.join(' '));
      } else {
          console.error("Editor instance is not ready or is destroyed.");
      }
  } catch (error) {
      console.error("Failed to insert HTML into Jodit editor:", error);
  }
};