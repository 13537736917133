import axios from "axios";
import { toast } from "react-toastify";

function debounce(callback, delay = 1000){
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    }
}

async function uploadImageUtility(file: File, fallbackSrc?: string){
    let srcValue = fallbackSrc ? fallbackSrc : "";

    try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/formupload`,
            formData,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            srcValue = response.data?.item?.src;
        }
    } catch (error) {
        console.error('Error uploading or fetching image:', error);
        toast.error('Upload failed!');
    }
    return srcValue;
};

// onChange = {debounce(e => {conosole.log(e.target.value)})}

export {
    debounce,
    uploadImageUtility,
}