/**
 * Format objects to work with react-select component
 * @param obj
 * @returns formatted obj { id, value, label }
 */
const formatObjects = (obj: any) => {
  if (obj && Object.keys(obj)?.length !== 0) {
    let arr = obj?.map((item: any) => {
      return { id: item.id, value: item.id, label: item.name || item.label };
    });

    return arr;
  }
};

/**
 * Format objects to work with Match list component
 * @param obj
 * @returns formatted obj { id, label }
 */
const formatDataWithLabel = (obj: any) => {
  console.log('object');
  if (obj && Object.keys(obj)?.length !== 0) {
    let arr = obj?.map((item: string, key: number) => {
      const stringID = `item-${key + 1}-${new Date().getTime()}`;

      return { id: stringID, label: item };
    });
    return arr;
  }
};
/**
 * Format objects to work with Match list component
 * @param obj
 * @returns formatted obj { id, label }
 */
const formatDataWithLabelForDnD = (obj: any) => {
  if (obj && Object.keys(obj)?.length !== 0) {
    let arr = obj?.map((item: any, key: number) => {
      let arr2 = item.map((el: any) => {
        const stringID = `item-${key + 1}-${new Date().getTime()}`;
        return { id: stringID, label: el };
      });
      return arr2;
    });
    return arr;
  }
};

/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
function getParsedJwt<T extends object = { [k: string]: string | number }>(
  token: string
): T | undefined {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch {
    return undefined;
  }
}

export {
  formatObjects,
  formatDataWithLabel,
  formatDataWithLabelForDnD,
  getParsedJwt,
};
